export const getPatientsById = /* GraphQL */ `
  query GetPatientBucket($patientId: ID!) {
    getPatientBucket(patientId: $patientId) {
      bulletinBoard
      agentId
      patientId
      patientFirstName
      patientLastName
      patientStatus
      dob
      homePhoneNumber

      patientProfile {
        patientInfo {
          preferredContactMethod
          address {
            city
            state
            streetName
            zip
          }
          email
          gender
          patientWeightLB
          cellphoneNumber
          preferredLanguage
          bestContact
          alternateContact {
            firstName
            lastName
            relationship
            phone
          }
          toContactPatient
          toLeaveMessage
          bestTimeToContact
          ssn
          hipaaContact
          preference {
            surveys {
              preferred
            }
          }
        }

        insuranceInfo {
          isPatientInsured
          primary {
            insurerId
            insurerName
            binNumber
            pcnNumber
            coveredBy
            email
            firstName
            groupId
            insuranceExpireDate
            lastName
            planName
            policyId
            state
            customerServicePhone
            relationship
            rxPlanName
            pharmacyPhone
            rxGroupNumber
            hasPharmacyBenefits
            payerId
          }
          secondary {
            insurerId
            insurerName
            binNumber
            pcnNumber
            coveredBy
            email
            firstName
            groupId
            insuranceExpireDate
            lastName
            planName
            policyId
            state
            customerServicePhone
            relationship
            rxPlanName
            pharmacyPhone
            rxGroupNumber
            hasPharmacyBenefits
            payerId
          }
          tertiary {
            insurerId
            insurerName
            binNumber
            pcnNumber
            coveredBy
            email
            firstName
            groupId
            insuranceExpireDate
            lastName
            planName
            policyId
            state
            customerServicePhone
            relationship
            rxPlanName
            pharmacyPhone
            rxGroupNumber
            hasPharmacyBenefits
            payerId
          }
        }

        allergyInfo {
          allergen
          entered
          reaction
          recordNumber
        }

        medicationInfo {
          discontinuationDate
          dosage
          frequency
          medication
          recordNumber
          startDate
        }

        weightInfo {
          changeFromLastKnown
          entered
          lastKnown
          recordNumber
          unitOfWeight
          weight
          weightLB
        }
      }

      hcpProfile {
        items {
          prescriberId
          patientId
          prescriber {
            NPINumber
            prescriberLastName
            prescriberFirstName
            taxIDNumber
            updatedAt
            officePhoneNumber
            officeFaxNumber
          }
        }
      }

      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }

      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }

      notes {
        date
        type
        note
        createdBy
      }

      updatedAt

      referral {
        archivedDrugReferrals {
          agentId
          archivedDate
          orderName
          archivedReferral {
            originalReceivedDate
            referralId
            noOfTreatments
            prescriberId
            discontinuation {
              reasons {
                details
                reasonType
              }
              notes
              patientStartedTherapy
              survey
              agentId
              date
            }
          }
        }
        drugReferrals {
          drugId
          drugName
          specialPharmName
          specialPharmPhoneNumber
          referralId
          prescriberId
          drugType

          patientHasStartedTherapy

          noOfTreatments

          firstTreatmentDate
          lastTreatmentDate
          inventorySource
          specialPharmName
          specialPharmPhoneNumber
          referralApproved
          scheduling
          archiveOrder
          reasonForArchiving
          isCompleted
          rxOrderFile
          originalReceivedDate

          referralOrder {
            orderName
            orderType
            orderingProvider
            orderDate
            orderExpires
            primaryDX {
              primaryDiagnosis
              description
              diagnosedBy
            }
            otherDX {
              description
              diagnosedBy
              primaryDiagnosis
            }
            medicationType
            administrations {
              adminSequenceNumber
              drugName
              maxOfTreatments
              route
              unitOfMeas
              approvedDosage
              calcDosage
              administer
              dosageFrequencyType
              dosageDayRange
              dosageEvery
              dosageDateTimeFrameEvery
              dosageFor
              dosageDateTimeFrameFor
              dose
            }
            preMedications {
              drugName
              maxOfTreatments
              route
              unitOfMeas
              approvedDosage
              administer
              isPreMed
            }
            notes
          }

          orderNotes {
            allergies {
              allergen
              entered
              reaction
              recordNumber
            }
            labTests {
              date
              note
              type
              drugName
              labTest
              labExpiration
              results
            }
            oralLeadIn {
              dateOfFirstInjection
              dateOfLastDose
            }
          }

          adverseEvent {
            date
            drugName
            details
          }

          clinical {
            clinicalNotes
            orderApproved
            orderDenied
            orderPending
            reason
            expirationDateOfApproval
          }

          discontinuation {
            reasons {
              details
              reasonType
            }
            notes
            patientStartedTherapy
            survey
            agentId
            date
          }
        }
      }

      benefitInvestigation {
        benefitCheckings {
          referralId
          welcomeCallCompleted
          callCompletionTime
          checkings {
            selectedLocationId
            isCompleted
            insuranceKey
            paRequired
            verifiedDate
            billingTaxId
            billingOverrideReason
            billingNPINumber
            groupId
            selectedBillingNPI
            selectedBillingTaxId
            selectedGroupId
            selectedProviderId
            policy {
              coPay
            }
          }
        }
      }

      priorAuthorization {
        denialTrackings {
          referralId
          insuranceKey
          denialReason
          mdoContacted
        }
        freeDrugs {
          deliveryDate
          orderDate
          orderNumber
          orderedQuantity
          pharmacyName

          referralId
          representativeName
        }
        priorAuthCheckings {
          referralId
          insuranceKey
          isCompleted
          approvalInfo {
            isSiteSpecific
            priorAuthNumber
            serviceFrom
            serviceTo
            numberOfApprovedUnits
            numberOfApprovedVisits
          }
          requestHistory {
            insuranceCompanyName
            personSpokeWith
            callReference
            submittedDate
            followUpDate
          }
          welcomeCalls {
            callTime
            agentId
            answered
          }
        }
      }

      surveys {
        collectedAt
        collectedBy
        description
        survey
        typeOfSurvey
        version
      }
    }
  }
`;

export const getPatientsByLastName = /* GraphQL */ `
  query GetPatientBucketByLastNameInLowerCase(
    $sPatientLastName: String!
    $filter: ModelPatientBucketFilterInput
    $nextToken: String
  ) {
    getPatientBucketByLastNameInLowerCase(
      sPatientLastName: $sPatientLastName
      filter: $filter
      nextToken: $nextToken
    ) {
      nextToken
      items {
        bulletinBoard
        agentId
        patientId
        patientFirstName
        patientLastName
        patientStatus
        dob
        homePhoneNumber

        patientProfile {
          patientInfo {
            preferredContactMethod
            address {
              city
              state
              streetName
              zip
            }
            email
            gender
            patientWeightLB
            cellphoneNumber
            preferredLanguage
            bestContact
            alternateContact {
              firstName
              lastName
              relationship
              phone
            }
            toContactPatient
            toLeaveMessage
            bestTimeToContact
            ssn
            hipaaContact
            preference {
              surveys {
                preferred
              }
            }
          }

          insuranceInfo {
            isPatientInsured
            primary {
              insurerId
              insurerName
              binNumber
              pcnNumber
              coveredBy
              email
              firstName
              groupId
              insuranceExpireDate
              lastName
              planName
              policyId
              state
              customerServicePhone
              relationship
              rxPlanName
              pharmacyPhone
              rxGroupNumber
              hasPharmacyBenefits
              payerId
            }
            secondary {
              insurerId
              insurerName
              binNumber
              pcnNumber
              coveredBy
              email
              firstName
              groupId
              insuranceExpireDate
              lastName
              planName
              policyId
              state
              customerServicePhone
              relationship
              rxPlanName
              pharmacyPhone
              rxGroupNumber
              hasPharmacyBenefits
              payerId
            }
            tertiary {
              insurerId
              insurerName
              binNumber
              pcnNumber
              coveredBy
              email
              firstName
              groupId
              insuranceExpireDate
              lastName
              planName
              policyId
              state
              customerServicePhone
              relationship
              rxPlanName
              pharmacyPhone
              rxGroupNumber
              hasPharmacyBenefits
              payerId
            }
          }

          allergyInfo {
            allergen
            entered
            reaction
            recordNumber
          }

          medicationInfo {
            discontinuationDate
            dosage
            frequency
            medication
            recordNumber
            startDate
          }

          weightInfo {
            changeFromLastKnown
            entered
            lastKnown
            recordNumber
            unitOfWeight
            weight
            weightLB
          }
        }

        hcpProfile {
          items {
            prescriberId
            patientId
            prescriber {
              NPINumber
              prescriberLastName
              prescriberFirstName
              taxIDNumber
              updatedAt
              officePhoneNumber
              officeFaxNumber
            }
          }
        }

        patientAssistantProgram {
          annualIncome
          eligibleForCoPay
          householdSize
          incomeDoc
        }

        patientDocuments {
          documentType
          documentPath
          date
          receivedAt
        }

        notes {
          date
          type
          note
          createdBy
        }

        updatedAt

        referral {
          archivedDrugReferrals {
            agentId
            archivedDate
            orderName
            archivedReferral {
              originalReceivedDate
              referralId
              noOfTreatments
              prescriberId
              discontinuation {
                reasons {
                  details
                  reasonType
                }
                notes
                patientStartedTherapy
                survey
                agentId
                date
              }
            }
          }
          drugReferrals {
            drugId
            drugName
            specialPharmName
            specialPharmPhoneNumber
            referralId
            prescriberId
            drugType
            patientHasStartedTherapy
            noOfTreatments
            firstTreatmentDate
            lastTreatmentDate
            inventorySource
            specialPharmName
            specialPharmPhoneNumber
            referralApproved
            scheduling
            archiveOrder
            reasonForArchiving
            isCompleted
            originalReceivedDate
            referralOrder {
              orderName
              orderType
              orderingProvider
              orderDate
              orderExpires
              primaryDX {
                primaryDiagnosis
                description
                diagnosedBy
              }
              otherDX {
                description
                diagnosedBy
                primaryDiagnosis
              }
              medicationType
              administrations {
                adminSequenceNumber
                drugName
                maxOfTreatments
                route
                unitOfMeas
                approvedDosage
                calcDosage
                administer
                dosageFrequencyType
                dosageDayRange
                dosageEvery
                dosageDateTimeFrameEvery
                dosageFor
                dosageDateTimeFrameFor
                dose
              }
              preMedications {
                drugName
                maxOfTreatments
                route
                unitOfMeas
                approvedDosage
                administer
                isPreMed
              }
              notes
            }

            orderNotes {
              allergies {
                allergen
                entered
                reaction
                recordNumber
              }
              labTests {
                date
                note
                type
                drugName
                labTest
                labExpiration
                results
              }
              oralLeadIn {
                dateOfFirstInjection
                dateOfLastDose
              }
            }

            adverseEvent {
              date
              drugName
              details
            }

            clinical {
              orderApproved
              orderDenied
              reason
              expirationDateOfApproval
            }

            discontinuation {
              reasons {
                details
                reasonType
              }
              notes
              patientStartedTherapy
              survey
              agentId
              date
            }
          }
        }

        benefitInvestigation {
          benefitCheckings {
            referralId
            welcomeCallCompleted
            callCompletionTime
            checkings {
              selectedLocationId
              isCompleted
              insuranceKey
              paRequired
              verifiedDate
              billingTaxId
              billingOverrideReason
              billingNPINumber
              groupId
              selectedBillingNPI
              selectedBillingTaxId
              selectedGroupId
              selectedProviderId
              policy {
                coPay
              }
            }
          }
        }

        priorAuthorization {
          denialTrackings {
            referralId
            insuranceKey
            denialReason
            mdoContacted
          }
          freeDrugs {
            deliveryDate
            orderDate
            orderNumber
            orderedQuantity
            pharmacyName

            referralId
            representativeName
          }
          priorAuthCheckings {
            referralId
            isCompleted
            approvalInfo {
              priorAuthNumber
              serviceFrom
              serviceTo
              numberOfApprovedUnits
              numberOfApprovedVisits
              isSiteSpecific
            }
            requestHistory {
              insuranceCompanyName
              personSpokeWith
              callReference
              submittedDate
              followUpDate
            }
            welcomeCalls {
              callTime
              agentId
              answered
            }
          }
        }

        surveys {
          collectedAt
          collectedBy
          description
          survey
          version
          typeOfSurvey
        }
      }
    }
  }
`;

export const getPatientsByDob = /* GraphQL */ `
  query GetPatientBucketByDob(
    $dob: AWSDate
    $filter: ModelPatientBucketFilterInput
  ) {
    getPatientBucketByDob(dob: $dob, filter: $filter) {
      items {
        agentId
        patientId
        patientFirstName
        patientLastName
        patientStatus
        dob
        homePhoneNumber

        patientProfile {
          patientInfo {
            preferredContactMethod
            address {
              city
              state
              streetName
              zip
            }
            email
            gender
            patientWeightLB
            cellphoneNumber
            preferredLanguage
            bestContact
            alternateContact {
              firstName
              lastName
              relationship
              phone
            }
            toContactPatient
            toLeaveMessage
            bestTimeToContact
            ssn
            hipaaContact
            preference {
              surveys {
                preferred
              }
            }
          }

          insuranceInfo {
            isPatientInsured
            primary {
              insurerId
              insurerName
              binNumber
              pcnNumber
              coveredBy
              email
              firstName
              groupId
              insuranceExpireDate
              lastName
              planName
              policyId
              state
              customerServicePhone
              relationship
              rxPlanName
              pharmacyPhone
              rxGroupNumber
              hasPharmacyBenefits
              payerId
            }
            secondary {
              insurerId
              insurerName
              binNumber
              pcnNumber
              coveredBy
              email
              firstName
              groupId
              insuranceExpireDate
              lastName
              planName
              policyId
              state
              customerServicePhone
              relationship
              rxPlanName
              pharmacyPhone
              rxGroupNumber
              hasPharmacyBenefits
              payerId
            }
            tertiary {
              insurerId
              insurerName
              binNumber
              pcnNumber
              coveredBy
              email
              firstName
              groupId
              insuranceExpireDate
              lastName
              planName
              policyId
              state
              customerServicePhone
              relationship
              rxPlanName
              pharmacyPhone
              rxGroupNumber
              hasPharmacyBenefits
              payerId
            }
          }

          allergyInfo {
            allergen
            entered
            reaction
            recordNumber
          }

          medicationInfo {
            discontinuationDate
            dosage
            frequency
            medication
            recordNumber
            startDate
          }

          weightInfo {
            changeFromLastKnown
            entered
            lastKnown
            recordNumber
            unitOfWeight
            weight
            weightLB
          }
        }

        hcpProfile {
          items {
            prescriberId
            patientId
            prescriber {
              NPINumber
              prescriberLastName
              prescriberFirstName
              taxIDNumber
              updatedAt
              officePhoneNumber
              officeFaxNumber
            }
          }
        }

        patientAssistantProgram {
          annualIncome
          eligibleForCoPay
          householdSize
          incomeDoc
        }

        patientDocuments {
          documentType
          documentPath
          date
          receivedAt
        }

        notes {
          date
          type
          note
          createdBy
        }

        updatedAt

        referral {
          archivedDrugReferrals {
            agentId
            archivedDate
            orderName
            archivedReferral {
              referralId
              noOfTreatments
              prescriberId
              discontinuation {
                reasons {
                  details
                  reasonType
                }
                notes
                patientStartedTherapy
                survey
                agentId
                date
              }
            }
          }
          drugReferrals {
            drugId
            drugName
            specialPharmName
            specialPharmPhoneNumber

            prescriberId
            drugType

            patientHasStartedTherapy

            noOfTreatments

            firstTreatmentDate
            lastTreatmentDate
            inventorySource
            specialPharmName
            specialPharmPhoneNumber
            referralApproved
            scheduling
            archiveOrder
            reasonForArchiving
            isCompleted
            originalReceivedDate

            referralOrder {
              orderName
              orderType
              orderingProvider
              orderDate
              orderExpires
              primaryDX {
                primaryDiagnosis
                description
                diagnosedBy
              }
              otherDX {
                description
                diagnosedBy
                primaryDiagnosis
              }
              medicationType
              administrations {
                adminSequenceNumber
                drugName
                maxOfTreatments
                route
                unitOfMeas
                approvedDosage
                calcDosage
                administer
                dosageFrequencyType
                dosageDayRange
                dosageEvery
                dosageDateTimeFrameEvery
                dosageFor
                dosageDateTimeFrameFor
                dose
              }
              preMedications {
                drugName
                maxOfTreatments
                route
                unitOfMeas
                approvedDosage
                administer
                isPreMed
              }
              notes
            }

            orderNotes {
              allergies {
                allergen
                entered
                reaction
                recordNumber
              }
              labTests {
                date
                note
                type
                drugName
                labTest
                labExpiration
                results
              }
              oralLeadIn {
                dateOfFirstInjection
                dateOfLastDose
              }
            }

            adverseEvent {
              date
              drugName
              details
            }

            clinical {
              orderApproved
              orderDenied
              reason
              expirationDateOfApproval
            }

            discontinuation {
              reasons {
                details
                reasonType
              }
              notes
              patientStartedTherapy
              survey
              agentId
              date
            }
          }
        }

        benefitInvestigation {
          benefitCheckings {
            referralId
            welcomeCallCompleted
            callCompletionTime
            checkings {
              selectedLocationId
              isCompleted
              insuranceKey
              paRequired
              verifiedDate
              billingTaxId
              billingOverrideReason
              billingNPINumber
              groupId
              selectedBillingNPI
              selectedBillingTaxId
              selectedGroupId
              selectedProviderId
              policy {
                coPay
              }
            }
          }
        }

        priorAuthorization {
          denialTrackings {
            referralId
            insuranceKey
            denialReason
            mdoContacted
          }
          freeDrugs {
            deliveryDate
            orderDate
            orderNumber
            orderedQuantity
            pharmacyName

            referralId
            representativeName
          }
          priorAuthCheckings {
            referralId
            isCompleted
            approvalInfo {
              priorAuthNumber
              serviceFrom
              serviceTo
              numberOfApprovedUnits
              numberOfApprovedVisits
              isSiteSpecific
            }
            requestHistory {
              insuranceCompanyName
              personSpokeWith
              callReference
              submittedDate
              followUpDate
            }
            welcomeCalls {
              callTime
              agentId
              answered
            }
          }
        }
        surveys {
          collectedAt
          collectedBy
          description
          survey
          version
          typeOfSurvey
        }
      }
    }
  }
`;

export const getPatientById = /* GraphQL */ `
  query GetPatientBucket($patientId: ID!) {
    getPatientBucket(patientId: $patientId) {
      bulletinBoard
      agentId
      patientId
      patientFirstName
      patientLastName
      patientStatus
      dob
      homePhoneNumber

      patientProfile {
        patientInfo {
          preferredContactMethod
          address {
            city
            state
            streetName
            zip
          }
          email
          gender
          patientWeightLB
          cellphoneNumber
          preferredLanguage
          bestContact
          alternateContact {
            firstName
            lastName
            relationship
            phone
          }
          toContactPatient
          toLeaveMessage
          bestTimeToContact
          ssn
          hipaaContact
          preference {
            surveys {
              preferred
            }
          }
        }

        insuranceInfo {
          isPatientInsured
          primary {
            insurerId
            insurerName
            binNumber
            pcnNumber
            coveredBy
            email
            firstName
            groupId
            insuranceExpireDate
            lastName
            planName
            policyId
            state
            customerServicePhone
            relationship
            rxPlanName
            pharmacyPhone
            rxGroupNumber
            hasPharmacyBenefits
            payerId
          }
          secondary {
            insurerId
            insurerName
            binNumber
            pcnNumber
            coveredBy
            email
            firstName
            groupId
            insuranceExpireDate
            lastName
            planName
            policyId
            state
            customerServicePhone
            relationship
            rxPlanName
            pharmacyPhone
            rxGroupNumber
            hasPharmacyBenefits
            payerId
          }
          tertiary {
            insurerId
            insurerName
            binNumber
            pcnNumber
            coveredBy
            email
            firstName
            groupId
            insuranceExpireDate
            lastName
            planName
            policyId
            state
            customerServicePhone
            relationship
            rxPlanName
            pharmacyPhone
            rxGroupNumber
            hasPharmacyBenefits
            payerId
          }
        }

        allergyInfo {
          allergen
          entered
          reaction
          recordNumber
        }

        medicationInfo {
          discontinuationDate
          dosage
          frequency
          medication
          recordNumber
          startDate
        }

        weightInfo {
          changeFromLastKnown
          entered
          lastKnown
          recordNumber
          unitOfWeight
          weight
          weightLB
        }
      }

      hcpProfile {
        items {
          prescriberId
          patientId
          prescriber {
            NPINumber
            prescriberLastName
            prescriberFirstName
            taxIDNumber
            updatedAt
            officePhoneNumber
            officeFaxNumber
          }
        }
      }

      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }

      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }

      notes {
        date
        type
        note
        createdBy
      }

      updatedAt

      referral {
        archivedDrugReferrals {
          agentId
          archivedDate
          orderName
          archivedReferral {
            originalReceivedDate
            referralId
            noOfTreatments
            prescriberId
            discontinuation {
              reasons {
                details
                reasonType
              }
              notes
              patientStartedTherapy
              survey
              agentId
              date
            }
          }
        }
        drugReferrals {
          drugId
          drugName
          specialPharmName
          specialPharmPhoneNumber
          referralId
          prescriberId
          drugType

          patientHasStartedTherapy

          noOfTreatments

          firstTreatmentDate
          lastTreatmentDate
          inventorySource
          specialPharmName
          specialPharmPhoneNumber
          referralApproved
          scheduling
          archiveOrder
          reasonForArchiving
          isCompleted
          rxOrderFile
          originalReceivedDate

          referralOrder {
            orderName
            orderType
            orderingProvider
            orderDate
            orderExpires
            primaryDX {
              primaryDiagnosis
              description
              diagnosedBy
            }
            otherDX {
              description
              diagnosedBy
              primaryDiagnosis
            }
            medicationType
            administrations {
              adminSequenceNumber
              drugName
              maxOfTreatments
              route
              unitOfMeas
              approvedDosage
              calcDosage
              administer
              dosageFrequencyType
              dosageDayRange
              dosageEvery
              dosageDateTimeFrameEvery
              dosageFor
              dosageDateTimeFrameFor
              dose
            }
            preMedications {
              drugName
              maxOfTreatments
              route
              unitOfMeas
              approvedDosage
              administer
              isPreMed
            }
            notes
          }

          orderNotes {
            allergies {
              allergen
              entered
              reaction
              recordNumber
            }
            labTests {
              date
              note
              type
              drugName
              labTest
              labExpiration
              results
            }
            oralLeadIn {
              dateOfFirstInjection
              dateOfLastDose
            }
          }

          adverseEvent {
            date
            drugName
            details
          }

          clinical {
            clinicalNotes
            orderApproved
            orderDenied
            orderPending
            reason
            expirationDateOfApproval
          }

          discontinuation {
            reasons {
              details
              reasonType
            }
            notes
            patientStartedTherapy
            survey
            agentId
            date
          }
        }
      }

      benefitInvestigation {
        benefitCheckings {
          referralId
          welcomeCallCompleted
          callCompletionTime
          checkings {
            selectedLocationId
            isCompleted
            insuranceKey
            paRequired
            verifiedDate
            billingTaxId
            billingOverrideReason
            billingNPINumber
            groupId
            selectedBillingNPI
            selectedBillingTaxId
            selectedGroupId
            selectedProviderId
            policy {
              coPay
            }
          }
        }
      }

      priorAuthorization {
        denialTrackings {
          referralId
          insuranceKey
          denialReason
          mdoContacted
        }
        freeDrugs {
          deliveryDate
          orderDate
          orderNumber
          orderedQuantity
          pharmacyName

          referralId
          representativeName
        }
        priorAuthCheckings {
          referralId
          insuranceKey
          isCompleted
          approvalInfo {
            isSiteSpecific
            priorAuthNumber
            serviceFrom
            serviceTo
            numberOfApprovedUnits
            numberOfApprovedVisits
          }
          requestHistory {
            insuranceCompanyName
            personSpokeWith
            callReference
            submittedDate
            followUpDate
          }
          welcomeCalls {
            callTime
            agentId
            answered
          }
        }
      }

      surveys {
        collectedAt
        collectedBy
        description
        survey
        typeOfSurvey
        version
      }
    }
  }
`;
