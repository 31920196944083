import { useContext } from "react";

// components
import { PatientQueryData, PatientSearchForm } from "@/components";
import { PatientSearchResults } from "@/components/patient-search-results";

// graphql
import { getQueryClient } from "@/provider";
import {
  getPatientsById,
  getPatientsByLastName,
  getPatientsByDob,
} from "@/graphql/queries";

// context
import { PatientContext } from "@/context/PatientContext";

// types
import { IPatient } from "@/@types/patient";

// styles
import { useStyles } from "./Home.styles";

const Home = () => {
  const { setPatients, resetPatients } = useContext(PatientContext);
  const styles = useStyles();

  const generateSearchPatientsParams = (searchQuery: PatientQueryData) => {
    const { id, lastName, dob, includeInactives } = searchQuery;
    const filter = includeInactives
      ? undefined
      : { patientStatus: { ne: "INACTIVE" } };
    let queryParams = null;
    if (id) {
      queryParams = {
        query: getPatientsById,
        path: "getPatientBucket",
        payload: {
          patientId: id,
        },
      };
    } else if (lastName) {
      queryParams = {
        query: getPatientsByLastName,
        path: "getPatientBucketByLastNameInLowerCase",
        payload: {
          sPatientLastName: lastName.toLowerCase(),
          filter,
        },
      };
    } else if (dob) {
      queryParams = {
        query: getPatientsByDob,
        path: "getPatientBucketByDob",
        payload: {
          dob: dob.format("YYYY-MM-DD"),
          filter,
        },
      };
    }
    return queryParams;
  };

  const onSearchPatients = async (searchQuery: PatientQueryData) => {
    try {
      const queryParams = generateSearchPatientsParams(searchQuery);
      if (queryParams) {
        const patientsData = await getQueryClient(queryParams);
        const normPatients: IPatient[] = patientsData
          .map((patient) => ({
            dob: patient.dob,
            firstName: patient.patientFirstName,
            lastName: patient.patientLastName,
            id: patient.patientId,
            referral: patient.referral,
            surveys: patient.surveys,
            patientProfile: patient.patientProfile,
          }))
          .sort((a, b) => {
            const nameA = a.firstName.toUpperCase(); // Ignore case while sorting
            const nameB = b.firstName.toUpperCase(); // Ignore case while sorting
            if (nameA < nameB) {
              return -1; // A should come before B
            }
            if (nameA > nameB) {
              return 1; // B should come before A
            }
            return 0; // Names are equal
          });
        setPatients(normPatients);
      }
    } catch (error) {
      resetPatients();
    }
  };

  return (
    <div className={styles.root}>
      {/* Search Form */}
      <PatientSearchForm onSearch={onSearchPatients} />

      {/* Patients List */}
      <PatientSearchResults />
    </div>
  );
};

export { Home };
