import { useState, useMemo, createContext, ReactNode } from "react";

// types
import { AgentContextType, IAgent } from "@@types/survey";

export const AgentContext = createContext<AgentContextType>({
  agent: null,
  setAgent: () => {},
});

const AgentProvider = ({ children }: { children: ReactNode }) => {
  const [agent, setAgentInfo] = useState<IAgent | null>(null);

  const setAgent = (userInfo: any) => {
    const { username, attributes } = userInfo;
    const { given_name, family_name } = attributes;
    setAgentInfo({ id: username, name: `${given_name} ${family_name}` });
  };

  const contextValue = useMemo(
    () => ({
      agent,
      setAgent,
    }),
    [agent]
  );

  return (
    <AgentContext.Provider value={contextValue}>
      {children}
    </AgentContext.Provider>
  );
};

export default AgentProvider;
