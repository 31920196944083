import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";
import { ThemeProvider } from "react-jss";
import { theme } from "@/theme";
import App from "@/App";

// contexts
import PatientProvider from "@/context/PatientContext";
import SurveyProvider from "@/context/SurveyContext";
import AgentProvider from "@/context/AgentContext";

// styles
import "antd/dist/reset.css";
import "./style.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

document.body.classList.add("auth-body");

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ConfigProvider theme={{}}>
        <SurveyProvider>
          <PatientProvider>
            <AgentProvider>
              <App />
            </AgentProvider>
          </PatientProvider>
        </SurveyProvider>
      </ConfigProvider>
    </ThemeProvider>
  </React.StrictMode>
);
