import devConfig from "./config.dev";
import devxConfig from "./config.devx";
import devcConfig from "./config.devc";
import stageConfig from "./config.stage";
import prodConfig from "./config.prod";

let config = devConfig;

const env = process.env.REACT_APP_ENV;
if (env === "devx") {
  config = devxConfig;
} else if (env === "devc") {
  config = devcConfig;
} else if (env === "stage") {
  config = stageConfig;
} else if (env === "prod") {
  config = prodConfig;
}

export default config;
