import type { Theme } from "./types";
import colors from "./colors";
import breakpoints from "./breakpoints";

const theme: Theme = {
  colors,
  breakpoints,
};

export { theme, Theme };
