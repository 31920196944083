/* eslint-disable max-len */
import { generateSurveyWarnings } from "@optml/alera-share";
import { QOL_PATIENT_TABLET_SURVEY } from "@/utils/common.utils";
import {
  generalPainLevel,
  common1to10Rate,
  commonYesNoField,
  common30daysField,
} from "../common/modelBlocks";
import PHQ9Model from "../common/PHQ9Model";

const warnings = generateSurveyWarnings();

export default {
  pages: [
    {
      elements: [
        // Category 1: Physical Assessment (PA)
        {
          type: "panel",
          name: "panel-PA",
          title: "Category 1: Physical Assessment",
          elements: [
            // Disease state status-Activity level
            {
              ...common1to10Rate,
              name: "PA-activity-level",
              title:
                "How hard is it to do all the activities you want to do because of your health?",
              minRateDescription: "Extremely difficult",
              maxRateDescription: "Very easy",
            },
            // Disease state status-Pain level Days
            {
              ...common30daysField,
              name: "PA-days-in-pain",
              title:
                "Over the past 30 days how many days did you experience pain?",
            },
            // Disease state status-Pain level
            {
              name: "general-pain-scale",
              type: "radiogroup",
              title: "When you have pain how bad is it?",
              choices: generalPainLevel,
              isRequired: true,
            },
            // Disease state status-Presence of disease effects (not feeling well)
            {
              ...common1to10Rate,
              name: "PA-disease-effects",
              title:
                "Today you are receiving treatment for a specific condition. With respect to this condition how well do you feel?",
              minRateDescription: "Horrible",
              maxRateDescription: "Very well",
            },
            // Disease state status-Presence of disease effects (not feeling well)
            {
              ...common30daysField,
              name: "PA-disease-effects-days",
              title:
                "Today you are receiving treatment for a specific condition. With respect to this condition, over the past 30 days how many days did you NOT feel well?",
            },
            // Disease state status-Appetite
            {
              ...common1to10Rate,
              name: "PA-hard-to-eat",
              title: "I can easily chew, swallow and eat food",
              minRateDescription: "Never",
              maxRateDescription: "Always",
            },
          ],
        },
      ],
    },
    {
      // Category 2: Emotional well-being
      elements: [
        {
          type: "panel",
          name: "panel-EWB",
          title: "Category 2: Emotional well-being",
          elements: [
            // Anxiety
            {
              ...common1to10Rate,
              name: "EWB-anxiety",
              title: "I frequently feel stress, anxious or worried",
              minRateDescription: "Always",
              maxRateDescription: "Never",
            },
            // Depression
            {
              ...common1to10Rate,
              name: "EWB-depression",
              title:
                "I feel sad, blue or depressed and/or have low energy, interest, pleasure",
              minRateDescription: "Always",
              maxRateDescription: "Never",
            },
            { ...warnings.selfHarmWarning },
            // ==================
            // PHQ9 form trigger
            // ==================
            { ...PHQ9Model },
            // Sleep
            {
              ...common1to10Rate,
              name: "EWB-sleep",
              title: "I often times have trouble sleeping",
              minRateDescription: "Always",
              maxRateDescription: "Never",
            },
          ],
        },
      ],
    },
    // Category 3: Cognition
    {
      elements: [
        {
          type: "panel",
          name: "panel-cognition",
          title: "Category 3: Cognition",
          elements: [
            // Remembering
            {
              ...common1to10Rate,
              name: "cognition-remembering",
              title:
                "I can easily remember things (such as facts, events, talks with friends etc)",
              minRateDescription: "Never",
              maxRateDescription: "Always",
            },
            { ...warnings.memoryWarning },
            // Health literacy level
            {
              ...common1to10Rate,
              name: "cognition-literacy-level",
              title: "I can understand information about my health",
              minRateDescription: "Never",
              maxRateDescription: "Always",
            },
            { ...warnings.healthLiteracyWarning },
            // Ability to problem solve
            {
              ...common1to10Rate,
              name: "cognition-problem-solving",
              title: "If I am faced with a problem I find it easy to solve it",
              minRateDescription: "Never",
              maxRateDescription: "Always",
            },
            { ...warnings.selfCareWarning },
          ],
        },
      ],
    },
    // Category 4: Level of Independence
    {
      elements: [
        {
          type: "panel",
          name: "panel-personal-development",
          title: "Category 4: Level of Independence",
          elements: [
            // Self-care
            {
              ...common1to10Rate,
              name: "LoI-self-care",
              title: "I am able to take care of my day to day needs",
              minRateDescription: "Never",
              maxRateDescription: "Always",
            },
            // Access to Caregiver (if needed)
            {
              ...common1to10Rate,
              name: "LoI-caregiver-access",
              title:
                "There are people in my life that can help me if I need it",
              minRateDescription: "Never",
              maxRateDescription: "Always",
            },
            // Ability to go places (drive, bus etc)
            {
              ...common1to10Rate,
              name: "LoI-transportation",
              title:
                "I am able to go places, (drive, bus, etc) such as to medical appointments, when I need to",
              minRateDescription: "Never",
              maxRateDescription: "Always",
            },
            { ...warnings.transportationWarningTablet },
            // Resources-Food
            {
              ...common1to10Rate,
              name: "LoI-food",
              title: "I have access to the food I need daily",
              minRateDescription: "Never",
              maxRateDescription: "Always",
            },
            { ...warnings.foodBenefitsWarningTablet },
          ],
        },
      ],
    },
    // Category 5: Managing Risks
    {
      elements: [
        {
          type: "panel",
          name: "panel-managing-risks",
          title: "Category 5: Managing Risks",
          elements: [
            // Hospital/ER
            {
              ...commonYesNoField,
              name: "risks-hospitalizations",
              title:
                "Have you had to go to the Hospital, Urgent Care and/or Emergency Room in the past 30 days (for yourself)?",
            },
            { ...warnings.hospitalizationWarning },
            // Adherence
            {
              ...common1to10Rate,
              name: "risks-adherence",
              title:
                "I find it easy to take my medications every day and/or schedule my infusions on time",
              minRateDescription: "Never",
              maxRateDescription: "Always",
            },
            { ...warnings.adherenceWarning },
            // Goal compliance
            {
              ...common1to10Rate,
              name: "risks-goals",
              title:
                "I find it easy to set goals and stay on track with my goals related to my health",
              minRateDescription: "Never",
              maxRateDescription: "Always",
            },
            { ...warnings.goalsWarning },
          ],
        },
      ],
    },
  ],
  showPrevButton: false,
  completedHtml: "Survey completed, please pass the tablet to the provider now",
  clearInvisibleValues: "onHidden",
  questionsOnPageMode: "singlePage",
  version: 1,
  surveyType: QOL_PATIENT_TABLET_SURVEY,
  surveyTitle: "Patient Survey",
};
