import React, { useContext } from "react";
import { Space, Col, Row, Tag } from "antd";
import {
  compareScores,
  getScoreRating,
  formatDateTimeToDefault,
} from "@optml/alera-share";
import {
  BookOutlined,
  CalendarOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  UserOutlined,
  WarningOutlined,
} from "@ant-design/icons";

// context
import { SurveyContext } from "@/context/SurveyContext";
import { PatientContext } from "@/context/PatientContext";

// utils
import { AGENT_TABLET } from "@/utils/common.utils";
import colors from "@/theme/colors";

// styles
import { useStyles } from "./SurveyFollowup.styles";

const SurveyFollowup = () => {
  const styles = useStyles();
  const { surveyFollowup, resetActiveSurvey } = useContext(SurveyContext);
  const { resetPatients } = useContext(PatientContext);

  const renderScoreChange = (scoreDiff: any) => {
    if (scoreDiff === 0) return null;
    if (scoreDiff > 0)
      return <sup style={{ color: "green" }}>+{scoreDiff}</sup>;

    return <sub style={{ color: "red" }}>{scoreDiff}</sub>;
  };

  const renderScores = () => {
    const scores = surveyFollowup.score;
    const priorScores = surveyFollowup.priorScore;

    // support for now up to 3 scores - display as columns
    const colSize = 24 / scores.length;

    return scores.map((score: any, i: number) => {
      const scoreDiff = compareScores(score, priorScores);
      return (
        <Col span={colSize} key={`survey-scores-${i}`}>
          <div className={styles.scoreType}>{score.type} Score:</div>
          <div>
            <span className={styles.scoreValue}>{score.score}</span>
            {renderScoreChange(scoreDiff)}
          </div>
          <div className={styles.scoreRating}>
            {getScoreRating(score, surveyFollowup)}
          </div>
        </Col>
      );
    });
  };

  const renderWorkItems = () => {
    if (surveyFollowup.workItems?.length > 0) {
      return surveyFollowup.workItems.map((workItem: any, i: number) => {
        return (
          <div className={styles.notes} key={`follow-up-workitem-${i}`}>
            <Space className={styles.warning}>
              <WarningOutlined />
              {workItem.description}
            </Space>
          </div>
        );
      });
    }

    return null;
  };

  const renderEduLinks = () => {
    if (surveyFollowup.education?.length > 0) {
      return surveyFollowup.education.map((guide: any, i: number) => {
        return (
          <div key={`follow-up-edulink-${i}`} className={styles.notes}>
            <Space>
              <BookOutlined />
              {guide.title}
            </Space>
          </div>
        );
      });
    }

    return (
      <div className={styles.notes}>
        <Space className={styles.info}>
          <InfoCircleOutlined />
          <span className={styles.text}>
            No resources available based on survey results
          </span>
        </Space>
      </div>
    );
  };

  const renderDateMeta = () => {
    const takenAt = surveyFollowup.collectedAt || formatDateTimeToDefault();
    const takenBy = surveyFollowup.collectedBy || null;

    return (
      <Row>
        <Col span={24}>
          <Space>
            <CalendarOutlined />
            Survey taken:{takenAt}
            {takenBy && takenBy === AGENT_TABLET && (
              <Tag color={colors.blue200}>survey completed via tablet</Tag>
            )}
          </Space>
        </Col>
        {takenBy && takenBy !== AGENT_TABLET && (
          <Col span={24}>
            <Space>
              <UserOutlined />
              Agent name: {takenBy}
            </Space>
          </Col>
        )}
      </Row>
    );
  };

  const renderScoreComparison = () => {
    if (surveyFollowup.score?.length > 0) {
      return (
        <>
          <Row className={styles.scores}>{renderScores()}</Row>
          {surveyFollowup.priorCollectedAt ? (
            <p className="gray-hint">
              Difference to the score shown against to the prior score from
              survey collected at <b>{surveyFollowup.priorCollectedAt}</b>
            </p>
          ) : null}
        </>
      );
    }

    return null;
  };

  if (surveyFollowup) {
    return (
      <div className="sv-page sv-body__page">
        <div className={styles.close}>
          <Space
            onClick={() => {
              resetActiveSurvey();
              resetPatients();
            }}
          >
            <CloseOutlined />
            CLOSE
          </Space>
        </div>
        <div className="row" style={{ paddingBottom: 15 }}>
          <div className="col-6">
            <h1>Survey Results</h1>
          </div>
          <div className="col-6">{renderDateMeta()}</div>
        </div>
        {renderScoreComparison()}
        <hr />
        <Row>
          <Col span={12}>
            <div className={styles.scoreType}>Survey Notes</div>
            {renderWorkItems()}
          </Col>
          <Col span={12}>
            <div className={styles.scoreType}>Educational Resources</div>
            {renderEduLinks()}
          </Col>
        </Row>
        <hr />
      </div>
    );
  }

  return null;
};

export default SurveyFollowup;
