export const addPatientSurvey = /* GraphQL */ `
  mutation addPatientSurvey(
    $agentId: String!
    $patientId: String!
    $patientSurvey: PatientSurveyInput!
  ) {
    addPatientSurvey(
      agentId: $agentId
      patientId: $patientId
      patientSurvey: $patientSurvey
    ) {
      message
      statusCode
    }
  }
`;

export const updatePatientSurvey = /* GraphQL */ `
  mutation updatePatientSurvey(
    $agentId: String!
    $patientId: String!
    $patientSurvey: PatientSurveyInput!
  ) {
    updatePatientSurvey(
      agentId: $agentId
      patientId: $patientId
      patientSurvey: $patientSurvey
    ) {
      message
      statusCode
    }
  }
`;

export const addUpdateNotes = /* GraphQL */ `
  mutation AddUpdateNotes($input: AddUpdateNotes!) {
    addUpdateNotes(input: $input) {
      agentId
      dob
      patientFirstName
      patientLastName
      homePhoneNumber
      notes {
        date
        type
        note
        createdBy
      }
    }
  }
`;
