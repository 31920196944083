import { Button, Checkbox, Form, Input, DatePicker, Typography } from "antd";
import { Dayjs } from "dayjs";

// styles
import { useStyles } from "./PatientSearchForm.styles";

const { Title } = Typography;

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export type PatientQueryData = {
  id?: string;
  lastName?: string;
  dob?: Dayjs;
  includeInactives?: boolean;
};

interface PatientSearchFormProps {
  onSearch?: (data: PatientQueryData) => void;
}

const PatientSearchForm = ({ onSearch }: PatientSearchFormProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Title className={styles.title} level={3}>
        Search Patients
      </Title>
      <Form
        {...formLayout}
        name="patient-search-form"
        autoComplete="off"
        initialValues={{ includeInactives: false }}
        onFinish={onSearch}
      >
        <Form.Item
          className={styles.formControl}
          label="Patient Last Name:"
          name="lastName"
        >
          <Input />
        </Form.Item>
        <Form.Item className={styles.formControl} label="Patient ID:" name="id">
          <Input />
        </Form.Item>
        <Form.Item
          className={styles.formControl}
          label="Date of Birth:"
          name="dob"
        >
          <DatePicker
            className={styles.datePicker}
            placeholder="MM/DD/YYYY"
            format="MM/DD/YYYY"
          />
        </Form.Item>
        <Form.Item
          className={styles.formControl}
          name="includeInactives"
          valuePropName="checked"
        >
          <Checkbox>Include Inactive Patients</Checkbox>
        </Form.Item>

        <Button className={styles.btnSearch} type="primary" htmlType="submit">
          Search
        </Button>
      </Form>
    </div>
  );
};

export { PatientSearchForm };
