import { createUseStyles } from "react-jss";
import { Theme } from "@/theme";

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    gap: 20,
    padding: 24,
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  patientList: {
    flex: 1,
    maxHeight: "100vh",
    overflow: "auto",
    padding: "10px 20px",
    border: `1px solid ${theme.colors.gray100T35}`,
    borderRadius: 8,
    background: theme.colors.white,
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      width: "100%",
    },
  },
  description: {
    fontSize: 14,
    color: theme.colors.blackT45,
  },
  patientTitle: {
    fontSize: "2rem",
    color: theme.colors.black,
  },
}));

export { useStyles };
