import { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Authenticator, View, useTheme } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

// components
import { BaseLayout, LogoFull, PoweredBy, SurveyLayout } from "@/components";

// pages
import { Home, Survey } from "@/pages";

// context
import { SurveyContext } from "@/context/SurveyContext";

// config
import config from "@/config";

Amplify.configure(config.aws);

const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <LogoFull width={480} />
      </View>
    );
  },
  Footer() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <PoweredBy />
      </View>
    );
  },
};

const App = () => {
  const { activeSurvey } = useContext(SurveyContext);
  const MainLayout = activeSurvey ? SurveyLayout : BaseLayout;
  const MainRouteElement = activeSurvey ? Survey : Home;

  return (
    <Authenticator components={components} hideSignUp>
      {({ user, signOut }) => (
        <BrowserRouter>
          <Routes>
            <Route element={<MainLayout user={user} signOut={signOut} />}>
              <Route path="/" element={<MainRouteElement />} />
            </Route>
            {/* Not Found Pages */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      )}
    </Authenticator>
  );
};

export default App;
