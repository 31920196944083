import patientTabletModel from "@/survey-models/AQCCA/QOL-Patient-Tablet-Survey-v1";
import { QOL_PATIENT_TABLET_SURVEY } from "@/utils/common.utils";

const surveyModelMapper = {
  [QOL_PATIENT_TABLET_SURVEY]: {
    description: "Patient Survey",
    versions: {
      1: patientTabletModel,
    },
    activeVersion: 1,
  },
};

// get correct model based on its type and version
//
// @param (required): modelType String
// @param (optional): version String | Number
// @returns Object
export const getSurveyModel = (modelType, version) => {
  const modelFromMapper = surveyModelMapper[modelType];

  // unknown modelType
  if (!modelFromMapper) {
    console.error(
      `Survey model with type '${modelType}' has not been found (surveyModelMapper.js)`
    );
    return null;
  }

  if (Object.hasOwn(modelFromMapper.versions, version)) {
    return modelFromMapper.versions[version];
  }

  // otherwise - version in unsupported or unknown
  console.error(
    `Passed version '${version}' has not been found for model '${modelType}'. 
Though, we used the last known version (surveyModelMapper.js)`
  );

  // use the active version for the model
  return modelFromMapper.versions[modelFromMapper.activeVersion];
};

export default surveyModelMapper;
