import { useState, useMemo, createContext, ReactNode } from "react";
import { formatDateToAWSDateTime } from "@optml/alera-share";
import { Auth } from "aws-amplify";

// types
import { ISurvey, SurveyContextType } from "@@types/survey";

// GraphQL
import { getQueryClient } from "@/provider";
import {
  addPatientSurvey,
  addUpdateNotes,
  updatePatientSurvey,
} from "@/graphql/mutations";
import surveyModelMapper, {
  getSurveyModel,
} from "@/survey-models/utils/surveyModelMapper";
import {
  AGENT_TABLET,
  QOL_PATIENT_TABLET_SURVEY,
  calculateSurveyScores,
  generateTabletSurveyChartNotes,
} from "@/utils/common.utils";

export const SurveyContext = createContext<SurveyContextType>({
  activeSurvey: null,
  surveyResults: null,
  setActiveSurvey: () => {},
  setSurveyResults: () => {},
  resetActiveSurvey: () => {},
  completeSurvey: async () => {},
  reviewSurvey: async () => {},
  surveyReviewMode: false,
  setSurveyReviewMode: () => {},
  surveyFollowup: null,
  setSurveyFollowup: () => {},
});

const uploadSurveyCall = async (surveyData: any, patientId: number) => {
  try {
    const { surveyType, version, surveyFinished } = surveyData.meta;

    const patientSurvey = {
      survey: JSON.stringify(surveyData),
      typeOfSurvey: surveyType,
      version,
      collectedAt: new Date(surveyFinished).toISOString(),
    };

    await getQueryClient({
      query: addPatientSurvey,
      path: "addPatientSurvey",
      payload: {
        agentId: AGENT_TABLET,
        patientId,
        patientSurvey,
      },
    });
  } catch (err) {
    //
  }
};

const uploadSurveyReviewCall = async (
  patientSurvey: any,
  patientId: number,
  agentId: string
) => {
  try {
    await getQueryClient({
      query: updatePatientSurvey,
      path: "updatePatientSurvey",
      payload: {
        agentId,
        patientId,
        patientSurvey,
      },
    });
  } catch (err) {
    //
  }
};

const uploadPatientNotes = async (
  notes: string,
  patientId: number,
  agentId: string
) => {
  try {
    const input = {
      patientId,
      agentId,
      notes: [
        {
          date: formatDateToAWSDateTime(),
          note: notes,
          type: "AQCCA",
          createdBy: agentId,
          modifiedNote: false, // send false to add new
        },
      ],
    };

    await getQueryClient({
      query: addUpdateNotes,
      path: "addUpdateNotes",
      payload: { input },
    });
  } catch (err) {
    //
  }
};

const SurveyProvider = ({ children }: { children: ReactNode }) => {
  const [activeSurvey, setActiveSurvey] = useState<ISurvey | null>(null);
  const [surveyResults, setSurveyResults] = useState<any>(null);
  const [surveyReviewMode, setSurveyReviewMode] = useState<boolean>(false);
  const [surveyFollowup, setSurveyFollowup] = useState<any>(null);

  const resetActiveSurvey = () => {
    setActiveSurvey(null);
    setSurveyReviewMode(false);
    setSurveyFollowup(null);
    setSurveyResults(null);
  };

  const completeSurvey = async (
    surveyData: any,
    patientId: number,
    surveyStarted: Date
  ) => {
    const jsonResults = JSON.parse(surveyData);
    const version = jsonResults.version || 1;
    const model = getSurveyModel(QOL_PATIENT_TABLET_SURVEY, version);

    // adding meta info for any future references
    jsonResults.meta = {
      surveyType: QOL_PATIENT_TABLET_SURVEY,
      version: model.version,
      surveyStarted,
      surveyFinished: new Date(),
    };

    // ======================
    // Calculate AQCCA & PHQ9 scores
    // ====================
    const score = calculateSurveyScores(jsonResults);
    jsonResults.score = score;

    // ======================
    // Generate chart notes based on results
    // ====================
    const notes = generateTabletSurveyChartNotes(jsonResults);

    // add the notes to survey meta data
    jsonResults.notes = notes;

    // ======================
    // Upload survey results
    // ====================
    await uploadSurveyCall(jsonResults, patientId);

    resetActiveSurvey();
    Auth.signOut();
    document.body.classList.add("auth-body");
  };

  const reviewSurvey = async (
    surveyData: any,
    patientId: number,
    agentId: string
  ) => {
    const jsonResults = JSON.parse(surveyData);
    const { surveyType, version, surveyFinished } = jsonResults.meta;
    const mapper: any = surveyModelMapper;
    const { description } = mapper[surveyType];

    // ======================
    // Calculate AQCCA & PHQ9 scores
    // ====================
    const score = calculateSurveyScores(jsonResults); // @TODO: use shared function
    jsonResults.score = score;

    // ======================
    // Generate chart notes based on results
    // ====================
    const notes = generateTabletSurveyChartNotes(jsonResults);
    await uploadPatientNotes(notes, patientId, agentId); // @TODO: implement

    // add the notes to survey meta data
    jsonResults.notes = notes;

    // ======================
    // Upload survey results
    // ====================
    const patientSurvey = {
      survey: JSON.stringify(jsonResults),
      typeOfSurvey: surveyType,
      description,
      version,
      collectedAt: surveyFinished,
    };
    await uploadSurveyReviewCall(patientSurvey, patientId, agentId);

    // close survey modals
    resetActiveSurvey();
  };

  const contextValue = useMemo(
    () => ({
      activeSurvey,
      setActiveSurvey,
      resetActiveSurvey,
      completeSurvey,
      reviewSurvey,
      surveyResults,
      setSurveyResults,
      surveyReviewMode,
      setSurveyReviewMode,
      surveyFollowup,
      setSurveyFollowup,
    }),
    [activeSurvey]
  );

  return (
    <SurveyContext.Provider value={contextValue}>
      {children}
    </SurveyContext.Provider>
  );
};

export default SurveyProvider;
