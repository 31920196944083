import type { Theme } from "./types";

const colors: Theme["colors"] = {
  white: "#ffffff",
  blue100: "#7dbcea",
  blue200: "#108ee9",
  blue300: "#005282",
  black: "#000000",
  blackT45: "rgba(0,0,0,.45)",
  black100: "rgb(5, 5, 5)",
  black100T6: "rgba(5, 5, 5, 0.06)",
  green: "#87d068",
  greenDark: "#39821a",
  gray100: "rgb(140, 140, 140)",
  gray100T35: "rgba(140, 140, 140, 0.35)",
  grayText: "#656565",
  warning: "#827300",
};

export default colors;
