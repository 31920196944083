import { ReactElement, useContext } from "react";
import { Avatar, Button, Space, List, Typography, Tag } from "antd";
import { ProfileOutlined, FormOutlined } from "@ant-design/icons";
import {
  getLastTabletSurvey,
  getPriorTabletSurvey,
  getTabletSurveyScores,
  generateSurveyNotes,
  generateEducationLinks,
  formatDateTimeToDefault,
} from "@optml/alera-share";
import dayjs from "dayjs";

// partials
import ListActiveOrders from "./subcomponents/ListActiveOrders";

// context
import { PatientContext } from "@/context/PatientContext";
import { SurveyContext } from "@/context/SurveyContext";
import { AgentContext } from "@/context/AgentContext";

// types
import { IPatient } from "@@types/patient";

// utils
import {
  generateInitials,
  hasPatientOptedOut,
  hasPendingSurvey,
  removeWarningsFromModel,
} from "@/utils/common.utils";

// styles
import { useStyles } from "./PatientSearchResults.styles";

// survey models
import surveyModel from "@/survey-models/AQCCA/QOL-Patient-Tablet-Survey-v1";
import { getSurveyModel } from "@/survey-models/utils/surveyModelMapper";

const { Text } = Typography;

const PatientSearchResults = () => {
  const { agent } = useContext(AgentContext);
  const { patients, resetPatients } = useContext(PatientContext);
  const {
    setActiveSurvey,
    completeSurvey,
    reviewSurvey,
    setSurveyReviewMode,
    setSurveyResults,
    setSurveyFollowup,
  } = useContext(SurveyContext);

  const styles = useStyles();

  const agentId = agent ? agent.id : "";

  return (
    <List
      className={styles.patientList}
      dataSource={patients}
      renderItem={(patientInfo: IPatient, idx) => {
        const { id, firstName, lastName, dob, surveys } = patientInfo;

        // actions
        const actions: ReactElement[] = [];
        const StartPatientSurvey = (
          <Button
            key="start-survey"
            type="primary"
            icon={<FormOutlined />}
            onClick={() => {
              const surveyStarted = new Date();

              // we need to remove warnings so patients won't see them
              const noWarningsModel = removeWarningsFromModel(surveyModel);

              setActiveSurvey({
                model: noWarningsModel,
                onFinish: (surveyData: any) => {
                  completeSurvey(surveyData, id, surveyStarted);
                  resetPatients();
                },
              });
            }}
          >
            Start Patient Survey
          </Button>
        );
        const OptOutBadge = <Tag color="red">Opted-out from surveys</Tag>;
        const ReviewPatientSurvey = (
          <Button
            key="review-survey"
            type="primary"
            className={styles.reviewButton}
            icon={<FormOutlined />}
            onClick={() => {
              const lastSurvey = getLastTabletSurvey(surveys);

              if (lastSurvey) {
                const { survey, collectedAt, collectedBy } = lastSurvey;
                const jsonResults = JSON.parse(survey);

                const priorSurvey = getPriorTabletSurvey(surveys);
                const priorScore = getTabletSurveyScores(priorSurvey);

                // get model by type
                const model = getSurveyModel(
                  jsonResults.meta.surveyType,
                  jsonResults.meta.version
                );

                // set the survey model
                setActiveSurvey({
                  model,
                  onFinish: (surveyData: any) => {
                    reviewSurvey(surveyData, id, agentId);
                    setSurveyFollowup(null);

                    // cleanup
                    resetPatients();
                  },
                });

                setSurveyReviewMode(true);

                // display results in edit mode
                setSurveyResults(survey);

                const workItems = generateSurveyNotes(jsonResults.notes);
                const education = generateEducationLinks(jsonResults);

                // followup modal
                setSurveyFollowup({
                  education,
                  workItems,
                  score: jsonResults.score || [],
                  priorScore: priorScore || [],
                  priorCollectedAt: priorSurvey?.collectedAt
                    ? formatDateTimeToDefault(priorSurvey?.collectedAt)
                    : null,
                  collectedAt: formatDateTimeToDefault(collectedAt),
                  collectedBy,
                  version: jsonResults.meta?.version || 1,
                  surveyType: jsonResults.meta?.surveyType,
                });
              } else {
                throw new Error("ReviewPatientSurvey: Last survey not found");
              }
            }}
          >
            Review Patient Survey
          </Button>
        );

        // no other checks in case patient opted out
        if (hasPatientOptedOut(patientInfo)) {
          actions.push(OptOutBadge);
        } else if (hasPendingSurvey(patientInfo?.surveys)) {
          actions.push(ReviewPatientSurvey);
        } else {
          actions.push(StartPatientSurvey);
        }

        return (
          <List.Item key={idx} actions={actions}>
            <List.Item.Meta
              title={
                <Text>
                  <Space align="center" size="middle">
                    <Avatar size={40}>
                      {generateInitials(firstName, lastName)}
                    </Avatar>
                    <Text className={styles.patientTitle}>
                      {firstName} {lastName}
                    </Text>
                  </Space>
                </Text>
              }
              description={
                <Space size="large">
                  <Space>
                    <Text className={styles.patientDetails}>
                      DOB: {dayjs(dob).format("MM/DD/YYYY")}
                    </Text>
                  </Space>
                  <Space>
                    <ProfileOutlined />
                    <Text className={styles.patientDetails}>
                      <ListActiveOrders patientInfo={patientInfo} />
                    </Text>
                  </Space>
                </Space>
              }
            />
          </List.Item>
        );
      }}
    />
  );
};

export { PatientSearchResults };
