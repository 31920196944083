import { Flex, Image, Text, useTheme } from "@aws-amplify/ui-react";

// assets
import optmlLogo from "@/assets/images/optml-infuse.png";

const PoweredBy = () => {
  const { tokens } = useTheme();

  return (
    <>
      <Flex direction="row" justifyContent="center" gap={3}>
        <Text color={tokens.colors.neutral[80]}>Powered by</Text>
        <Image alt="Powered by OptML Infuse" src={optmlLogo} width={100} />
      </Flex>
      <Flex direction="row" justifyContent="center" gap={3}>
        <Text color={tokens.colors.gray100T35} fontSize={12}>
          app version: {process.env.REACT_APP_VERSION}
        </Text>
      </Flex>
    </>
  );
};

export { PoweredBy };
