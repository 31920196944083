export const last30daysOptions = [
  { value: 10, text: "0-3 days" },
  { value: 9, text: "4-6 days" },
  { value: 8, text: "7-9 days" },
  { value: 7, text: "10-12 days" },
  { value: 6, text: "13-15 days" },
  { value: 5, text: "16-18 days" },
  { value: 4, text: "19-21 days" },
  { value: 3, text: "22-24 days" },
  { value: 2, text: "25-27 days" },
  { value: 1, text: "28-30 days" },
];

export const common30daysField = {
  type: "radiogroup",
  choices: last30daysOptions,
  isRequired: true,
};

export const generalPainLevel = [
  { value: 10, text: "No Pain" },
  {
    value: 9,
    text: "Notice pain, does not interfere with activities",
  },
  { value: 8, text: "Sometimes distracts me" },
  { value: 7, text: "Distracts me, can do usual activities" },
  { value: 6, text: "Interrupts some activities" },
  { value: 5, text: "Hard to ignore, avoid usual activities" },
  {
    value: 4,
    text: "Focus of attention, prevents doing daily activities",
  },
  { value: 3, text: "Awful, hard to do anything" },
  {
    value: 2,
    text: "Can’t bear the pain, unable to do anything",
  },
  {
    value: 1,
    text: "As bad as it could be, nothing else matters",
  },
];

const NOT_AT_ALL = 0;
const SEVERAL_DAYS = 1;
const MORE_THAN_HALF = 2;
const EVERY_DAY = 3;

export const phq9Options = [
  { value: NOT_AT_ALL, text: "Not at all" },
  { value: SEVERAL_DAYS, text: "Several Days" },
  { value: MORE_THAN_HALF, text: "More than one-half of the days" },
  { value: EVERY_DAY, text: "Nearly every day" },
];

export const phq9SkippingMapper = (phq9Value) => {
  if (phq9Value === NOT_AT_ALL) return 10;
  if (phq9Value === SEVERAL_DAYS) return 7;
  if (phq9Value === MORE_THAN_HALF) return 4;
  if (phq9Value === EVERY_DAY) return 1;

  return 0;
};

export const phq9Answer = {
  type: "radiogroup",
  choices: phq9Options,
  isRequired: true,
};

export const defaultNumRates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const common1to10Rate = {
  type: "rating",
  rateValues: defaultNumRates,
  isRequired: true,
};

export const yesNoOptions = [
  { value: true, text: "Yes" },
  { value: false, text: "No" },
];

export const commonYesNoField = {
  type: "radiogroup",
  choices: yesNoOptions,
  isRequired: true,
};
