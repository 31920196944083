/* eslint-disable max-len */
import { dateDiffInDays, surveyWarnings } from "@optml/alera-share";
import { IPatient, PatientSurvey } from "@@types/patient";
import { last30daysOptions } from "@/survey-models/common/modelBlocks";

// constants
export const QOL_PATIENT_TABLET_SURVEY = "QOL-Patient-Tablet-Survey";
export const AGENT_TABLET = "aleracare-tablet";

export const hasActiveOrders = (patient: IPatient | null): boolean => {
  return Boolean(
    patient?.referral?.drugReferrals &&
      patient.referral.drugReferrals.length > 0
  );
};

// check results and comparing dates based on risk
// returns a boolean, if true - survey is required
export const isSurveyRequiredByRisk = (surveyData: any) => {
  const { score, meta } = JSON.parse(surveyData.survey);
  const aqccaScore = score.find(
    (scoreEntry: any) => scoreEntry.type === "AQCCA"
  );

  // require survey if prev result has not been found
  if (!aqccaScore) return true;

  const prevScore = aqccaScore.score;

  let daysByRisk = 60; // Generally well or Very well

  // define risk level based on model version
  if (meta.version === 1) {
    if (prevScore < 115) {
      daysByRisk = 30; // Poor or At Risk
    } else if (prevScore < 135) {
      daysByRisk = 45; // Average
    }
  } else {
    // move logic for version 1 if we update models at some point
  }

  // comparing dates now
  const surveyDate = new Date(surveyData.collectedAt);
  const today = new Date();
  const diffDays = dateDiffInDays(surveyDate, today);

  // display survey if last survey was long time ago, based on prev results risk value
  return diffDays >= daysByRisk;
};

export const hasPendingSurvey = (patientSurveys: PatientSurvey[] | null) => {
  if (patientSurveys && patientSurveys.length > 0) {
    const tabletSurveys = patientSurveys
      .filter((survey) => survey.typeOfSurvey === QOL_PATIENT_TABLET_SURVEY)
      .filter((surveyData) => {
        const { meta, score } = JSON.parse(surveyData.survey);
        if (!score) return false;
        // even if tablet version will not allow patients to skip - nurses still can do that from their end
        return meta.isSurveySkipped !== true;
      });

    // no prev results means - no pending surveys
    if (!tabletSurveys.length) return false;

    // last element in array - is the most recent survey
    const lastSurvey = tabletSurveys.at(-1);

    return lastSurvey?.collectedBy === AGENT_TABLET;
  }

  // no prev results - we allow to run the survey
  return false;
};

export const hasPatientOptedOut = (patient: any): boolean => {
  return Boolean(
    patient?.patientProfile?.patientInfo?.preference?.surveys?.preferred ===
      false
  );
};

// integer parser, works with negative numbers too
// returns parsed string as a number
// returns NaN otherwise
export function filterInt(value: any) {
  if (/^[-+]?(\d+|Infinity)$/.test(value)) {
    return Number(value);
  }
  return NaN;
}

// this method also has been copied to `aic-client` repo
// if any changes needed - please, make sure to update the logic in both repos
export const calculatePatientSurveyScore = (results: any) => {
  let score = 0;

  Object.keys(results).forEach((property) => {
    // === Result filters ===
    // 1 — We can sum up numbers only
    // So, first we need to separate numbers
    const valueInt = filterInt(results[property]);
    const isNumber = !Number.isNaN(valueInt);

    // 2 — It requires to filter out all PHQ9 answers from the final calculations
    const isPHQ9Related = property.includes("PHQ9");

    // 3 - Q12: If 10 skip question 13 and assign a value of 10
    // if (property === "LoI-self-care" && valueInt === 10) {
    //   score += 10;
    // }

    // 4 - Q1: No (assign 10 to question 3 and skip that question)
    // if (property === "PA-activity-level" && results[property] === false) {
    //   score += 10;
    // }

    if (isNumber && !isPHQ9Related) {
      score += valueInt;
    }
  });

  return score;
};

export const generateInitials = (firstName: string, lastName: string): string =>
  `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`;

export const calculatePHQ9Score = (results: any) => {
  // need to separate non-PHQ9 from PHQ9
  if (
    Array.isArray(results) &&
    results.some((res: any) => res.includes("PHQ9"))
  )
    return null;

  let score = 0;

  Object.keys(results).forEach((property: any) => {
    // === Result filters ===
    // 1 — We can sum up numbers only
    // So, first we need to separate numbers
    const valueInt = filterInt(results[property]);
    const isNumber = !Number.isNaN(valueInt);

    // 2 — It requires to keep only PHQ9 answers for the final calculations
    const isPHQ9Related = property.includes("PHQ9");

    // 3 — Exclude PHQ9-Q10 from the PHQ9 total scoring
    const isExcludedQuestion = property === "PHQ9-Q10";

    if (isNumber && isPHQ9Related && !isExcludedQuestion) {
      score += valueInt;
    }
  });

  return score;
};

export const calculateSurveyScores = (jsonResults: any) => {
  if (!jsonResults) return null;

  const qolScore = calculatePatientSurveyScore(jsonResults);
  const phq9Score = calculatePHQ9Score(jsonResults);

  const score = [{ type: "AQCCA", score: qolScore }];

  if (phq9Score !== null) {
    score.push({ type: "PHQ9", score: phq9Score });
  }

  return score;
};

export const flipPainScaleValue = (surveyValue: number) => {
  // Makes an opposite value for pain scale score, e.g.:
  // 10 => 1, 9 => 2, ..., 1 => 10
  // Base value equals to 11 - it's a sum for all scores (9+2 or 7+4)
  const baseValue = 11;

  return baseValue - surveyValue;
};

export const generateTabletSurveyChartNotes = (results: any) => {
  const chartNotes = [];
  const found = results?.score.find((element: any) => element.type === "PHQ9");

  // NOTE: this pain scale is a bit tricky, because we give 10 as score,
  // but, at the same time - it should be an opposite:
  // (no pain gives 10 as AQCCA score and 1 for the real pain scale level)
  // Same when you select answer with value 7 - it'll be translated to 4 in real scale level
  const flippedPainScore = flipPainScaleValue(results["general-pain-scale"]);
  if (flippedPainScore > 5) {
    chartNotes.push(`Patient reports a ${flippedPainScore} on pain scale`);
  }

  if (results["PA-days-in-pain"] < 8) {
    const daysValue = last30daysOptions.find(
      (option: any) => option.value === results["PA-days-in-pain"]
    );
    if (daysValue) {
      chartNotes.push(
        `Patient reports experiencing pain ${daysValue?.text} during the past month`
      );
    }
  }

  if (results["PA-disease-effects-days"] < 8) {
    const daysValue = last30daysOptions.find(
      (option: any) => option.value === results["PA-disease-effects-days"]
    );
    if (daysValue) {
      chartNotes.push(
        `Patient reports not feeling well due to their condition ${daysValue?.text} during the past month`
      );
    }
  }

  if (results["PA-hard-to-eat"] < 4) {
    chartNotes.push(
      "Patient reports significant difficulty with eating. Recommend weight monitoring. See current weight at this visit"
    );
  }

  // PHQ9 trigger
  if (results["EWB-depression"] < 6) {
    chartNotes.push(
      `Signs/symptoms of depression reported/observed. PHQ-9 assessment score ${found?.score}`
    );
  }

  if (results["cognition-literacy-level"] < 4) {
    chartNotes.push(
      "Observed patient has low health literacy. Recommend continuous education efforts"
    );
  }

  if (results["cognition-remembering"] < 4) {
    chartNotes.push(
      "Patient reports significant forgetfulness. Provided patient with tools/resources to address"
    );
  }

  if (results["cognition-problem-solving"] < 4) {
    chartNotes.push(
      "Patient experiencing difficulty problem solving health concerns. Provided tools/resources to address"
    );
  }

  if (results["LoI-self-care"] < 4 || results["LoI-caregiver-access"] < 4) {
    chartNotes.push(
      "Patient reports self care needs and lack of resources. Recommend assessing if would benefit from Case Management"
    );
  }

  if (results["risks-adherence"] < 6) {
    chartNotes.push(
      "Patient experiences challenges with adherene to therapy. Provided education and tools"
    );
  }

  if (results["risks-hospitalizations"] === true) {
    chartNotes.push("Patient reports recent Hospitalization/ER visit");
  }

  const notesLog = chartNotes.join("\n");
  return notesLog;
};

export const isSurveyWarning = (surveyElement: any) =>
  surveyWarnings.includes(surveyElement.name);

export const removeWarningsFromModel = (surveyModel: any) => {
  // deep cloning of the original object
  // @NOTE: for some reason the spread operator doesn't work properly and it mutates the original object
  const clonedModel = JSON.parse(JSON.stringify(surveyModel));

  for (let i = clonedModel.pages.length - 1; i >= 0; i--) {
    const { elements } = clonedModel.pages[i].elements[0];
    for (let j = elements.length - 1; j >= 0; j--) {
      if (isSurveyWarning(elements[j])) {
        elements.splice(j, 1);
      }
    }
  }

  return clonedModel;
};
