import { createUseStyles } from "react-jss";
import { Theme } from "@/theme";

const useStyles = createUseStyles((theme: Theme) => ({
  warning: {
    fontSize: 14,
    color: theme.colors.warning,
  },
  info: {
    fontSize: 16,
    color: theme.colors.blue300,
  },
  close: {
    textAlign: "right",
    cursor: "pointer",
  },
  padding15: {
    paddingBottom: 15,
  },
  scores: {
    textAlign: "center",
    backgroundColor: theme.colors.gray100T35,
    borderRadius: 5,
    padding: "15px 0",
    marginBottom: 15,
  },
  scoreType: {
    fontSize: 20,
    color: theme.colors.blue300,
  },
  scoreValue: {
    fontSize: 28,
    fontWeight: "bold",
    color: theme.colors.blue300,
  },
  scoreRating: {
    fontWeight: "bolder",
    fontStyle: "italic",
    fontSize: 14,
    color: theme.colors.grayText,
  },
  text: {
    fontSize: 14,
  },
  notes: {
    padding: "5px 12px",
  },
}));

export { useStyles };
