import { useContext } from "react";
import { Survey } from "survey-react-ui";
import { StylesManager, Model } from "survey-core";

// context
import { SurveyContext } from "@/context/SurveyContext";

// partials
import SurveyFollowup from "./SurveyFollowup";

// default survey styling
import "survey-core/modern.min.css";

interface SurveyResults {
  data: any;
}

StylesManager.applyTheme("modern");

const SurveyEngine = () => {
  const { activeSurvey, surveyResults, surveyReviewMode, surveyFollowup } =
    useContext(SurveyContext);

  const attachResults = (res: SurveyResults) => {
    const results = JSON.stringify(res.data);

    if (activeSurvey?.onFinish) {
      activeSurvey.onFinish(results); // callback from survey context
    }
  };

  if (activeSurvey) {
    const survey = new Model(activeSurvey.model);
    survey.onComplete.add(attachResults);

    // pre-populate collected survey results
    if (surveyResults) {
      const data = JSON.parse(surveyResults);
      survey.data = data;

      // in review mode we allow to edit results
      // otherwise it'll be just readonly (mode set to `display`)
      survey.mode = surveyReviewMode ? "" : "display";
    }

    return (
      <>
        {surveyFollowup && <SurveyFollowup />}
        <Survey model={survey} />
      </>
    );
  }

  return null;
};

export { SurveyEngine };
