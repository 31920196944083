import { createUseStyles } from "react-jss";
import { Theme } from "@/theme";

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.colors.green,
    color: theme.colors.white,
  },
  patientName: {
    fontSize: 32,
    fontWeight: 500,
  },
}));

export { useStyles };
