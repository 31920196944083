const config = {
  aws: {
    aws_project_region: "us-east-1",
    aws_cognito_identity_pool_id:
      "us-east-1:5f65ca0a-b412-4ac1-8e0e-4a5928bf2aef",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_zyjZaPWvn",
    aws_user_pools_web_client_id: "60akkvlt8hitcl5m0m2o4h3u8g",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_user_files_s3_bucket: "aicdatastore175312-test",
    aws_user_files_s3_bucket_region: "us-east-1",
    endpoints: [
      {
        name: "test",
        endpoint:
          "https://wwzww7yzrfcxba2wwcsnzx2ale.appsync-api.us-east-1.amazonaws.com/graphql",
      },
    ],
    oauth: {},
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
};

export default config;
