import { useEffect, useContext } from "react";
import { AmplifyUser } from "@aws-amplify/ui";
import { UseAuthenticator } from "@aws-amplify/ui-react-core";
import { Outlet } from "react-router-dom";
import { Button, Layout, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";

// components
import { LogoFull, PoweredBy } from "@/components";

// context
import { AgentContext } from "@/context/AgentContext";

// styles
import { useStyles } from "./BaseLayout.styles";

const { Header, Footer, Content } = Layout;

interface BaseLayoutProps {
  signOut?: UseAuthenticator["signOut"];
  user?: AmplifyUser;
}

const BaseLayout = ({ user, signOut }: BaseLayoutProps) => {
  const styles = useStyles();
  const { agent, setAgent } = useContext(AgentContext);

  useEffect(() => {
    if (user) {
      // populate user info via context
      setAgent(user);

      document.body.classList.remove("auth-body");
    }
  }, [user]);

  return (
    <Layout className={styles.layout}>
      <Header className={styles.header}>
        <LogoFull width={150} />
        <Space className={styles.userName}>
          <UserOutlined />
          {agent?.name}
        </Space>
        <Button
          type="primary"
          onClick={() => {
            document.body.classList.add("auth-body");
            if (signOut) signOut();
          }}
        >
          Sign out
        </Button>
      </Header>
      <Content className={styles.content}>
        <Outlet />
      </Content>
      <Footer className={styles.footer}>
        <PoweredBy />
      </Footer>
    </Layout>
  );
};

export { BaseLayout };
