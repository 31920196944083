import { useState, useMemo, createContext, ReactNode } from "react";
import { PatientContextType, IPatient } from "@/@types/patient";

export const PatientContext = createContext<PatientContextType>({
  patients: [],
  setPatients: () => {},
  currentPatientInfo: null,
  setCurrentPatientInfo: () => {},
  resetPatients: () => {},
});

const PatientProvider = ({ children }: { children: ReactNode }) => {
  const [patients, setPatients] = useState<IPatient[]>([]);
  const [currentPatientInfo, setCurrentPatientInfo] = useState<IPatient | null>(
    null
  );

  const getPatient = (id: number) => {
    return patients.find((patient) => patient.id === id);
  };

  const resetPatients = () => setPatients([]);

  const contextValue = useMemo(
    () => ({
      patients,
      setPatients,
      getPatient,
      currentPatientInfo,
      setCurrentPatientInfo,
      resetPatients,
    }),
    [patients, currentPatientInfo]
  );

  return (
    <PatientContext.Provider value={contextValue}>
      {children}
    </PatientContext.Provider>
  );
};

export default PatientProvider;
