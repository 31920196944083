import { createUseStyles } from "react-jss";
import { Theme } from "@/theme";

const useStyles = createUseStyles((theme: Theme) => ({
  layout: {
    minHeight: "100vh",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 64,
    color: theme.colors.white,
    backgroundColor: theme.colors.blue100,
  },
  content: {
    minHeight: 120,
  },
  footer: {
    textAlign: "center",
    color: theme.colors.white,
    backgroundColor: theme.colors.blue100,
  },
  userName: {
    fontSize: 16,
    color: theme.colors.black100,
  },
}));

export { useStyles };
