import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import { UseAuthenticator } from "@aws-amplify/ui-react-core";

// components
import { LogoFull } from "@/components";

// context
import { PatientContext } from "@/context/PatientContext";
import { SurveyContext } from "@/context/SurveyContext";

// styles
import { useStyles } from "./SurveyLayout.styles";

const { Footer, Content } = Layout;

interface SurveyLayoutProps {
  signOut?: UseAuthenticator["signOut"];
}

const SurveyLayout = ({ signOut }: SurveyLayoutProps) => {
  const styles = useStyles();
  const { resetPatients } = useContext(PatientContext);
  const { resetActiveSurvey } = useContext(SurveyContext);

  document.onvisibilitychange = () => {
    if (document.visibilityState === "hidden") {
      // clean up search results and any survey leftovers
      resetPatients();
      resetActiveSurvey();

      // forcely sign the user out
      if (signOut) signOut();
      document.body.classList.add("auth-body");
    }
  };

  return (
    <Layout className={styles.layout}>
      <Content className={styles.content}>
        <Outlet />
      </Content>
      <Footer className={styles.footer}>
        <LogoFull width={150} />
      </Footer>
    </Layout>
  );
};

export { SurveyLayout };
