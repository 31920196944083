// utils
import { hasActiveOrders } from "@/utils/common.utils";
import { IPatient } from "@@types/patient";

interface ListProps {
  patientInfo: IPatient;
}

const ListActiveOrders = ({ patientInfo }: ListProps) => {
  let listStr = "No current orders";

  if (hasActiveOrders(patientInfo)) {
    const flatList = patientInfo?.referral.drugReferrals?.map((referral) => {
      return referral.drugName;
    });
    listStr = `Current Orders: ${flatList?.join(", ")}`;
  }

  return <div>{listStr}</div>;
};

export default ListActiveOrders;
