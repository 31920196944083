import { createUseStyles } from "react-jss";
import { Theme } from "@/theme";

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    width: 420,
    padding: 24,
    border: `1px solid ${theme.colors.black100T6}`,
    borderRadius: 8,
    background: theme.colors.white,
  },
  title: {
    "&.ant-typography": {
      fontSize: 24,
    },
  },
  formControl: {
    marginBottom: 8,
    "& .ant-form-item-label": {
      paddingBottom: 4,
    },
  },
  datePicker: {
    width: "100%",
  },
  btnSearch: {
    marginTop: 16,
    width: "100%",
    height: 36,
    fontSize: 14,
  },
}));

export { useStyles };
