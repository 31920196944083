import { Image } from "@aws-amplify/ui-react";

// types
import { IImage } from "@@types/image";

// assets
import logo from "@/assets/images/aleracare.svg";

const LogoFull = ({ width }: IImage) => {
  return <Image alt="AleraCare" src={logo} width={width} />;
};

export { LogoFull };
